import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './styles.css'; // Import your new CSS file

/**
 * Converts a 24-hour time string ("HH:MM:SS") to a 12-hour time string ("h:MM AM/PM").
 * E.g. "14:10:00" => "2:10 PM"
 */
function formatTime24To12(timeStr) {
  if (!timeStr) return "";

  // Example input: "14:10:00"
  // Split to ["14", "10", "00"]
  const [hourStr, minuteStr] = timeStr.split(":");
  let hour = parseInt(hourStr, 10);
  let minute = minuteStr || "00";

  // Determine AM/PM
  const suffix = hour >= 12 ? "PM" : "AM";

  // Convert 0 => 12 AM, 13 => 1 PM, etc.
  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour -= 12;
  }

  // Return e.g. "2:10 PM"
  return `${hour}:${minute} ${suffix}`;
}

const COURSE_OPTIONS = [
  { value: '', label: 'Any Course' },
  { value: 'City Park', label: 'City Park' },
  { value: 'Evergreen', label: 'Evergreen' },
  { value: 'Harvard Gulch', label: 'Harvard Gulch' },
  { value: 'Kennedy', label: 'Kennedy' },
  { value: 'Overland Park', label: 'Overland Park' },
  { value: 'Wellshire', label: 'Wellshire' },
  { value: 'Willis Case', label: 'Willis Case' },
];

function SearchPage() {
  const [date, setDate] = useState('');
  const [course, setCourse] = useState('');
  const [timeMin, setTimeMin] = useState('');
  const [timeMax, setTimeMax] = useState('');
  const [teeTimes, setTeeTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Track whether the user has clicked "Search" at least once
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);
    setHasSearched(true);

    const params = new URLSearchParams();
    if (date) params.append('date', date);
    if (course) params.append('course', course);
    if (timeMin) params.append('time_min', timeMin);
    if (timeMax) params.append('time_max', timeMax);

    try {
      const response = await fetch(`https://api.denvertts303.com/db_tee_times?${params.toString()}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setTeeTimes(data.tee_times);
    } catch (err) {
      console.error('Error fetching tee times:', err);
      setError('There was an error fetching tee times. Please try again.');
    }
    setLoading(false);
  };

  // Reset the time fields AND clear search results
  const handleResetTimes = () => {
    setTimeMin('');
    setTimeMax('');
    setTeeTimes([]);
    setHasSearched(false);
    // If you'd also like to reset date/course, uncomment below:
    setDate('');
    setCourse('');
  };

  return (
    <div>
      <h1 className="main-heading">Denver Golf Tee Times</h1>

      <form onSubmit={handleSearch} className="form">
        <div className="form-group">
          <label htmlFor="dateInput">Date:</label>
          <input
            id="dateInput"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="courseInput">Course:</label>
          <select
            id="courseInput"
            value={course}
            onChange={(e) => setCourse(e.target.value)}
            className="form-control"
          >
            {COURSE_OPTIONS.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </div>

        {/* Time Min / Time Max with .inline-item for proper alignment */}
        <div className="form-inline">
          <div className="inline-item">
            <label htmlFor="timeMinInput">Time Min:</label>
            <input
              id="timeMinInput"
              type="time"
              value={timeMin}
              onChange={(e) => setTimeMin(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="inline-item">
            <label htmlFor="timeMaxInput">Time Max:</label>
            <input
              id="timeMaxInput"
              type="time"
              value={timeMax}
              onChange={(e) => setTimeMax(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        {/* Buttons for Search and Reset */}
        <div className="form-inline">
          <button type="submit" className="btn btn-primary">
            Search
          </button>
          <button
            type="button"
            onClick={handleResetTimes}
            className="btn btn-green"
          >
            Reset Times
          </button>
        </div>
      </form>

      {loading && <p className="loading-text">Loading...</p>}
      {error && <p className="error-text">{error}</p>}

      {!loading && !error && hasSearched && teeTimes.length === 0 && (
        <p className="notice-text">No Available Tee Times</p>
      )}

      {teeTimes.length > 0 && (
        <div>
          <h2 className="section-heading">Search Results</h2>
          <ul className="results-list">
            {teeTimes.map((entry, index) => (
              <li key={index}>
                {/* Convert "14:10:00" => "2:10 PM" via helper */}
                <strong>{formatTime24To12(entry.time)}</strong> - {entry.course || 'N/A'}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

function PreferencesForm() {
  const [email, setEmail] = useState('');
  const [singleDate, setSingleDate] = useState('');
  const [timeMin, setTimeMin] = useState('');
  const [timeMax, setTimeMax] = useState('');
  const [course, setCourse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setMessage(null);
    setLoading(true);

    if (!singleDate) {
      setError('Please select a date.');
      setLoading(false);
      return;
    }

    const payload = {
      email,
      date_min: singleDate,
      date_max: singleDate,
      time_min: timeMin || null,
      time_max: timeMax || null,
      course: course || null,
    };

    try {
      const response = await fetch('https://api.denvertts303.com/preferences', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.status === 409) {
        const data = await response.json();
        setError(data.detail || 'This preference already exists.');
        setLoading(false);
        return;
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      await response.json();
      setMessage('User Preference Saved');
      setEmail('');
      setSingleDate('');
      setTimeMin('');
      setTimeMax('');
      setCourse('');

      setTimeout(() => {
        setMessage(null);
      }, 1000);

    } catch (err) {
      console.error('Error creating preference:', err);
      setError(`Could not save preference: ${err.message}`);
    }
    setLoading(false);
  };

  return (
    <div>
      <h2 className="section-heading">Set Email Alerts</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label htmlFor="emailInput">Email:</label>
          <input
            id="emailInput"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="singleDateInput">Date:</label>
          <input
            id="singleDateInput"
            type="date"
            value={singleDate}
            onChange={(e) => setSingleDate(e.target.value)}
            required
            className="form-control"
          />
        </div>

        <div className="form-inline">
          <div className="inline-item">
            <label htmlFor="timeMinPref">Time Min:</label>
            <input
              id="timeMinPref"
              type="time"
              value={timeMin}
              onChange={(e) => setTimeMin(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="inline-item">
            <label htmlFor="timeMaxPref">Time Max:</label>
            <input
              id="timeMaxPref"
              type="time"
              value={timeMax}
              onChange={(e) => setTimeMax(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="coursePref">Course:</label>
          <select
            id="coursePref"
            value={course}
            onChange={(e) => setCourse(e.target.value)}
            className="form-control"
          >
            {COURSE_OPTIONS.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="btn btn-green">
          Save Preferences
        </button>
      </form>

      {loading && <p className="loading-text">Saving preference...</p>}
      {error && <p className="error-text">{error}</p>}
      {message && <p className="success-text">{message}</p>}
    </div>
  );
}

function App() {
  return (
    <div className="app-container">
      <SearchPage />
      <PreferencesForm />
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
